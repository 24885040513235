:root {
  --main-bg-color: #4CAF50;
  --secondary-bg-color: #7e21bc;
  --main-text-color: #333;
  --secondary-text-color: #555;
  --alert-color: #f10d09;
  --font-family: 'Arial', sans-serif;
  --border-radius: 8px;
  --padding: 15px;
  --transition-duration: 0.3s;
}

body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9); 
  text-align: center;
}

h1, h2, h3 {
  text-align: center;
  font-weight: bold;
}

h1 {
  color: var(--alert-color);
  margin: 20px 0;
  font-size: 15em;
}

h2 {
  color: var(--main-text-color);
  font-size: 2.2em;
}

h3 {
  color: var(--secondary-text-color);
  font-size: 3em;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input[type='password'], input[type='number'] {
  padding: var(--padding);
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: var(--border-radius);
  width: 100%;
  box-sizing: border-box;
}

input[type='password'] {
  font-size: 1.5em;
}

input[type='number'] {
  font-size: 4em;
  text-align: center;
}

button {
  padding: var(--padding);
  border: none;
  border-radius: var(--border-radius);
  color: white;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  margin-top: 10px;
  transition: background-color var(--transition-duration) ease;
}

.start_button {
  font-size: 5em;
  background-color: var(--main-bg-color);
}

.regular_button, .continue_button {
  font-size: 1.5em;
}

.regular_button{
  background-color: var(--main-bg-color);

}
.continue_button, .continue_button_big {
  background-color: var(--secondary-bg-color);
}

.continue_button_big {
  font-size: 3em;
}

.counters {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.circle-progress {
  width: 200px;
  height: 200px;
  margin-top: 5em;
  position: relative;
}

.circle-progress svg {
  transform: scale(1.8);
}

.circle-progress text {
  fill: var(--main-text-color);
  font-size: 2.5em;
  font-weight: bold;
  font-family: var(--font-family);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 20px;
    height: 90vh;
  }

  h1 {
    font-size: 10em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 5.5em;
  }

  button {
    padding: 12px;
    font-size: 1.3em;
  }

  input[type='number'] {
    font-size: 6em;
    padding: 12px;
  }

  input[type='password'] {
    font-size: 1.3em;
    padding: 12px;
  }

  .circle-progress {
    width: 250px;
    height: 250px;
  }

  .circle-progress text {
    font-size: 3em;
  }
}
